import styled from '@emotion/styled';
import { IconPlayClean } from './icons';
import { Spinner } from './styles';
import { ColorKey } from './theme';
import { usePlayerService } from './utils';

export type Size = 'inherit' | 'small' | 'large' | 'huge' | 'medium';

const iconSizes = {
  small: 0.5,
  inherit: 0.75,
  medium: 1,
  large: 2,
  huge: 6,
};

const wrapperSizes = {
  small: 1.5,
  inherit: 2,
  medium: 3,
  large: 6,
  huge: 6,
};

const Icon = styled.span<{ size?: Size; color?: ColorKey }>(({ size = 'inherit', theme }) => ({
  width: `${iconSizes[size]}rem`,
  height: `${iconSizes[size]}rem`,
  position: 'absolute',
  zIndex: 1,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) scale(1)',
  lineHeight: 0,
  color: theme.palette.background[800][0],
  '& svg': {
    width: '100%',
    height: '100%',
  },
}));

const PlayButtonWrapper = styled('div')<{ hide?: boolean }>(({ hide }) => ({
  transition: 'opacity 333ms linear',
  opacity: hide ? 0 : 1,
  position: 'absolute',
  zIndex: 1,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) scale(1)',
  cursor: 'pointer',
}));

const IconButtonWrapper = styled.div<{ size?: Size }>(({ size = 'inherit', theme }) => ({
  backgroundColor: theme.palette.primary[900][0],
  transition: 'transform 200ms linear',
  width: `${wrapperSizes[size]}rem`,
  height: `${wrapperSizes[size]}rem`,
  borderRadius: '50%',
  position: 'relative',
  '&:hover, &:active, &:focus': {
    backgroundColor: theme.palette.primary[800][0],
    transform: 'scale(1.1)',
  },
}));

const LivePlayButton = ({ id, size, hide }: { id: string; size: Size, hide?: boolean }) => {
  const [handlePlay, { loading }] = usePlayerService({ id });
  const type = 'p';
  return <PlayButtonWrapper hide={hide}>
    <IconButtonWrapper onClick={() => handlePlay(type)} size={size}>
      {loading ? (
        <Spinner size={size} />
      ) : (
        <Icon size={size}>
          <IconPlayClean />
        </Icon>
      )}
    </IconButtonWrapper>
  </PlayButtonWrapper>;
};

export default LivePlayButton;
