import React from 'react';

export default function IconRightArrow() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6567 8.34277L8.99989 13.9996" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M14.6567 8.34277L8.99989 2.68592" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
