import styled from '@emotion/styled';
import { ColorKey } from 'common/theme';

const Badge = styled.div<{ color?: ColorKey }>(({ theme, color = 'primary' }) => {
  const [bg, fg] = theme.palette[color][900];

  return {
    display: 'inline-block',
    backgroundColor: bg,
    color: fg,
    borderTopLeftRadius: theme.radius.default,
    borderBottomLeftRadius: theme.radius.default,
    borderRadius: theme.radius.default,
    ...theme.font.responsiveSize(0),
    fontWeight: theme.font.weight.medium,
    lineHeight: theme.font.lineHeight.compact,
    border: 'none',
    padding: '.25em .5em',
  };
});

export default Badge;
