import styled from '@emotion/styled';
import { Live, fetchLiveStream, WidgetItemSlider, WidgetLiveItemCarousel, WidgetBase, CompactConditionalProps, getCompactConditionalProp } from 'common/apiService';
import { liveStreamWidgetsIdExtractor } from 'common/extractLiveStreamWidgetsId';
import HottestLiveList from 'common/HottestLiveList';
import { __t } from 'common/i18n';
import Layout from 'common/Layout/Layout';
import LiveArchiveList from 'common/LiveArchiveList';
import LivePlayButton from 'common/LivePlayButton';
import LiveScheduleTable, { EmptyScheduleState } from 'common/LiveScheduleTable';
import { Badge, Card, Flex, Text } from 'common/styles';
import SpinnerButton from 'common/styles/SpinnerButton';
// import { setUserCookieToSEntry } from 'common/utils/cookieManage';
import { widgetsConditionalFlagChecker } from 'common/WidgetsConditionCheck';
import Head from 'next/head';
import { useEffect, useState } from 'react';

export default function LiveStreaming(props: Live) {
  const [topItems, setTopItems] = useState<WidgetItemSlider[] | undefined>(undefined);
  const [hotItems, setHotItems] = useState<WidgetLiveItemCarousel[] | undefined>(undefined);
  const [conductorItems, setConductorItems] = useState<Array<WidgetBase<'live_stream', WidgetLiveItemCarousel>> | undefined>(undefined);
  const [archiveItems, setArchiveItems] = useState<WidgetItemSlider[] | undefined>(undefined);
  const [hotTitle, setHotTitle] = useState<string | undefined>(undefined);
  const [archiveTitle, setArchiveTitle] = useState<string | undefined>(undefined);
  const [playableList, setPlayableList] = useState<CompactConditionalProps[]>([]);
  const [columnsOfConductor, setColumnsOfConductor] = useState<number>(3);
  const [playAbleLoading, setPlayableLoading] = useState(false);

  useEffect(() => {
    const { widgets } = props;
    if (widgets && widgets.length > 0) {
      const itemIds = liveStreamWidgetsIdExtractor(widgets);
      fetchCompactConditionalFlags(itemIds);

      const topItems = widgets.find((element) => element.specific_data_type === 'livestream-top');
      setTopItems(topItems?.configuration.items as WidgetItemSlider[]);

      const hotItems = widgets.find((element) => element.specific_data_type === 'livestream-hot');

      setHotItems(hotItems?.configuration.items as WidgetLiveItemCarousel[]);
      setHotTitle(hotItems?.display_title);

      const archiveItems = widgets.find((element) => element.specific_data_type === 'livestream-archive');
      setArchiveItems(archiveItems?.configuration.items as WidgetItemSlider[]);
      setArchiveTitle(hotItems?.display_title);

      const conductorItems = widgets.filter((element) => {
        return element.specific_data_type === 'livestream-conductor';
      });

      setConductorItems(conductorItems as Array<WidgetBase<'live_stream', WidgetLiveItemCarousel>>);
    }

    if (typeof window === 'undefined') return;
    setColumnsOfConductor(window.screen.width > 768 ? 7 : 3);
    window.addEventListener('resize', (ev: any) => {
      if (ev && ev.target && ev.target.screen) {
        if (typeof ev.target.screen.width === 'number' && ev.target.screen.width > 768) {
          setColumnsOfConductor(7);
          return;
        }
        if (typeof ev.target.screen.width === 'number' && ev.target.screen.width <= 768) {
          setColumnsOfConductor(3);
          return;
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCompactConditionalFlags = async (body) => {
    setPlayableLoading(true);
    try {
      const res = await getCompactConditionalProp(body);
      setPlayableList(res.data);
      setPlayableLoading(false);
    } catch (error) {
      setPlayableLoading(false);
    }
  };

  return (
    <Layout>
      <Head>
        <title>{__t('live.page.title')} | {__t('siteName')}</title>
      </Head>
      {!props.widgets || props.widgets.length === 0 && <div style={{ marginTop: '3rem' }}><EmptyScheduleState /></div>}
      {topItems && topItems.length > 0 && <TopBanner badge={topItems[0].schedule_text} playAbleLoading={playAbleLoading}
        title={topItems[0].title} id={topItems[0].short_id}
        playable={widgetsConditionalFlagChecker<WidgetItemSlider, CompactConditionalProps>(topItems, 'short_id', playableList, 'short_id', 'conditional_flag', 'playable', 'playable')[0].playable}
        imageUrl={topItems[0].image_path} />}
      {hotItems && hotItems.length > 0 && <HottestLiveList playAbleLoading={playAbleLoading}
        items={widgetsConditionalFlagChecker<WidgetLiveItemCarousel, CompactConditionalProps>(hotItems, 'content_id', playableList, 'id', 'conditional_flag', 'playable', 'playable')}
        title={hotTitle} />}
      {archiveItems && archiveItems.length > 0 && <LiveArchiveList items={archiveItems} display_title={archiveTitle}
        playAbleLoading={playAbleLoading} />}
      {conductorItems && conductorItems.length > 0 && <LiveScheduleTable playAbleLoading={playAbleLoading}
        conductorItems={conductorItems.map((item: WidgetBase<'live_stream', WidgetLiveItemCarousel>) => {
          return {
            ...item,
            configuration: {
              ...item.configuration,
              items: widgetsConditionalFlagChecker<WidgetLiveItemCarousel, CompactConditionalProps>(item.configuration.items, 'content_id', playableList, 'id', 'conditional_flag', 'playable', 'playable'),
            },
          };
        })}
        columnsOfConductor={columnsOfConductor}
      />}
    </Layout>
  );
}

const TopBanner = ({ imageUrl, badge, title, playable, id, playAbleLoading }) => {
  return <>
    <Flex justify='center' align='center' fullWidth>
      <LiveCard orientation='horizontal' url={imageUrl}>
        {!playAbleLoading && playable ? <LivePlayButton id={id} size="large" /> : playAbleLoading ? <SpinnerButton size={'large'} /> : ''}
        {badge && <LiveBadge>{badge}</LiveBadge>}
      </LiveCard>
    </Flex>
    <Flex justify='center' align='center'>
      <LiveCardTitle variant='body1' pt={1} >{title}</LiveCardTitle>
    </Flex>
  </>;
};

const LiveCard = styled(Card)<{ url: string }>(({ theme, url }) => ({
  paddingBottom: '42.18%',
  width: '75%',
  backgroundImage: `url(${url}?w=1125)`,
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

const LiveCardTitle = styled(Text)(({ theme }) => ({
  width: '75%',
  fontWeight: theme.font.weight.medium,
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

export const LiveBadge = styled(Badge)(({ theme }) => ({
  background: theme.palette.info[100][0],
  borderRadius: '24px',
  bottom: 0,
  position: 'absolute',
  margin: '8px',
  padding: '8px',
  fontWeight: 'bold',
  fontSize: '14px',
}));

export const getServerSideProps = async () => {
  const { data } = await fetchLiveStream();
  // setUserCookieToSEntry(req);

  return {
    props: data,
  };
};
