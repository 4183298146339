export function widgetsConditionalFlagChecker<
  WidgetItemModel extends Record<string, any>,
  ValidationSourceModel extends Record<string, any>
>(
  widgets: Array<WidgetItemModel>,
  fieldNameOfWidget: string,
  validationSource: Array<ValidationSourceModel>,
  fieldNameOfMatcherInValidationSource: string,
  fieldNameOfFlagInValidationSource: string,
  conditionInFlag: string,
  targetField: string,
): Array<WidgetItemModel> {
  if (validationSource.length === 0) return widgets;
  for (let i = 0; i < widgets.length; i++) {
    if (typeof widgets[i] === 'object') {
      if (Object.keys(widgets[i]).includes(fieldNameOfWidget)) {
        const valueOfFieldNameOfWidget = widgets[i][fieldNameOfWidget];
        const validationSourceObjectIndex: number = validationSource.findIndex(
          (item: ValidationSourceModel) => item[fieldNameOfMatcherInValidationSource] === valueOfFieldNameOfWidget);
        if (validationSourceObjectIndex < 0) {
          const item: any = widgets[i];
          item[targetField] = false;
        } else {
          if (typeof validationSource[validationSourceObjectIndex][fieldNameOfFlagInValidationSource] === 'string' &&
            validationSource[validationSourceObjectIndex][fieldNameOfFlagInValidationSource].includes(conditionInFlag)) {
            const item: any = widgets[i];
            item[targetField] = true;
          } else {
            const item: any = widgets[i];
            item[targetField] = false;
          }
        }
      } else {
        const item: any = widgets[i];
        item[targetField] = false;
      }
    } else {
      continue;
    }
  }
  return widgets;
}
