import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { WidgetBase, WidgetLiveItemCarousel } from './apiService';
import IconEmptyLiveSchedule from './icons/IconEmptyLiveSchedule';
import LivePlayButton from './LivePlayButton';
import LiveTabBar, { LiveTab } from './LiveTabBar';
import { Card, Flex, Text } from './styles';
import LiveSpinnerBtn from './styles/LiveSpinnerBtn';
import theme from './theme/theme';

const LiveScheduleTable = ({ conductorItems, columnsOfConductor, playAbleLoading }: { conductorItems: Array<WidgetBase<'live_stream', WidgetLiveItemCarousel>>, columnsOfConductor: number, playAbleLoading: boolean }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [tabs, setTabs] = useState<LiveTab[] | undefined>(undefined);
  const [activeSchedule, setActiveSchedule] = useState<WidgetLiveItemCarousel[]>([]);
  const [schedule, setSchedule] = useState<WidgetLiveItemCarousel[][]>([]);

  useEffect(() => {
    const tabs = conductorItems.map((i, index) => ({
      display_title: i.display_title,
      short_id: i.short_id,
      i: index,
    }));
    setTabs(tabs);

    const scheduleList: WidgetLiveItemCarousel[][] = conductorItems.map((i) => i.configuration.items);
    setSchedule(scheduleList);
    setActiveSchedule(scheduleList[activeIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setActiveScheduleIndex = (index) => {
    setActiveIndex(index);
    setActiveSchedule(schedule?.[index]);
  };
  return <>
    <HeaderText mt={4}>جدول پخش</HeaderText>
    <Flex justify='center' align='center' fullWidth mt={2} position='relative'>
      <LiveScheduleTableCard orientation='horizontal'>
        {tabs &&
          <LiveTabBar
            activeIndex={activeIndex}
            tabs={tabs}
            onChange={(index) => setActiveScheduleIndex(index)}
            mb={{ xs: 4, md: 5 }}
            columnsOfConductor={columnsOfConductor}
          />}
        <PanelMainWrapper>
          <TabPanelScroll>
            <TabPanel columnsOfConductor={columnsOfConductor}>
              {activeSchedule && activeSchedule.length > 0 ? <ScheduleList items={activeSchedule} playAbleLoading={playAbleLoading}
                columnsOfConductor={columnsOfConductor} />
                : <EmptyScheduleState />}
            </TabPanel>
          </TabPanelScroll>
        </PanelMainWrapper>
      </LiveScheduleTableCard>
    </Flex>
  </>;
};

const LiveScheduleTableCard = styled(Card)(({ theme }) => ({
  width: '75%',
  padding: '16px 32px 32px 32px',
  backgroundColor: theme.palette.background[700][0],
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

export default LiveScheduleTable;

const HeaderText = styled(Text)<{ isArchive?: boolean }>(({ theme }) => ({
  textAlign: 'center',
  fontWeight: theme.font.weight.medium,
  fontSize: '1.5rem !important',
}));

const ScheduleList = ({ items, columnsOfConductor, playAbleLoading }: {
  items: WidgetLiveItemCarousel[], columnsOfConductor: number,
  playAbleLoading: boolean
}) => {
  return <PanelList>
    {items.map((i) => {
      return <PanelListItem key={i.content_id} columnsOfConductor={columnsOfConductor}>
        <Flex direction={columnsOfConductor > 3 ? 'row' : 'column-reverse'}>
          {!playAbleLoading && i.playable ? <div style={{ position: 'relative', marginRight: '16px', width: '48px' }} >
            <LivePlayButton id={i.content_id as string} size='small' />
          </div> : playAbleLoading
            ? <div style={{ position: 'relative', marginRight: '16px', width: '48px' }} >
              <LiveSpinnerBtn size={'small'} /></div> : <div style={{ position: 'relative', marginRight: '16px', width: '48px' }} >
            </div>}
          <Flex mr={'auto'}>
            <Text mr={1} variant="body3" style={{ color: theme.palette.foreground[500][0], lineHeight: 2 }}>{i.live_end_time}</Text>-<Text variant="body3" ml={1} style={{ color: theme.palette.foreground[500][0], lineHeight: 2 }}>{i.live_start_time}</Text>
          </Flex>
          <Text style={{ direction: 'rtl' }} variant="body2">{i.video_content ? i.video_content.title : ''}</Text>
        </Flex>
      </PanelListItem>;
    })}
  </PanelList>;
};

const PanelList = styled.ul(() => ({
  listStyle: 'none',
}));

const PanelListItem = styled.li<{ columnsOfConductor: number }>(({ columnsOfConductor, theme }) => ({
  borderBottom: `1px solid ${theme.palette.foreground[100][0]}`,
  padding: columnsOfConductor > 3 ? '20px' : '16px 0',
  '&:last-child': {
    borderBottom: '0px',
  },
}));

const PanelMainWrapper = styled.div(({ theme }) => ({
  padding: '24px 8px',
  background: theme.palette.background[600][0],
  borderRadius: '4px',
}));

const TabPanel = styled.div<{ columnsOfConductor: number }>(({ columnsOfConductor, theme }) => ({
  textAlign: 'center',
  background: theme.palette.background[600][0],
  borderRadius: '4px',
  minHeight: '510px',
  padding: columnsOfConductor > 3 ? '24px 32px' : '16px',
}));

const TabPanelScroll = styled.div(() => ({
  height: '360px',
  overflowY: 'scroll',
  direction: 'ltr',
  '&::-webkit-scrollbar-track': {
    webkitBoxShadow: `inset 0 0 6px ${theme.palette.background[100][0]}`,
    backgroundColor: theme.palette.background[300][0],
  },
  '&::-webkit-scrollbar': {
    width: '6px',
    backgroundColor: theme.palette.background[300][0],
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.background[100][0],
  },
}));

// const SideFlex = styled.div(({ type, mb }: { type: 'L' | 'R', mb: boolean }) => ({
//   display: 'block',
//   textAlign: type === 'R' ? 'left' : 'right',
//   alignItems: 'center',
//   overflowWrap: 'break-word',
//   marginBottom: mb ? '8px' : 'none',
// }));

export const EmptyScheduleState = () => {
  return <>
    <Flex direction={'column'} align="center" justify="center">
      <IconEmptyLiveSchedule />
      <Text mt={2}>برنامه ای برای پخش موجود نیست.</Text></Flex>
  </>;
};
