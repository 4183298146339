import styled from '@emotion/styled';
import withStyleSystem from './withStyleSystem';
import { SpacingProps } from './withStyleSystem/withSpacingStyles';
import { paperBg } from './styles/Paper';
import LiveTab from './styles/LiveTab';
import { Flex, Icon, Text } from './styles';
import { useState } from 'react';
import { IconLeftArrow } from './icons';
import IconRightArrow from './icons/IconRightArrow';
import theme from './theme/theme';

const LiveTabBarWrapper = withStyleSystem(styled.div(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background[paperBg.main][0],
  display: 'flex',
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  marginBottom: '8px !important',
})));

export type LiveTab = {
  display_title: string,
  short_id: string,
  i: number,
};

export type scheduleData = { title: string, time: string, coming_soon?: boolean | undefined, id: string }

type TabBarProps = {
  activeIndex: number,
  onChange: (index: number) => void,
  tabs: LiveTab[],
  columnsOfConductor: number,
  customTabWidth?: string,
  tabTextAlign?: 'left' | 'right',
}

export default function LiveTabBar({ tabTextAlign, tabs, onChange,
  activeIndex, customTabWidth, columnsOfConductor, ...rest }: TabBarProps & SpacingProps) {
  const [startEnd, setStartEnd] = useState<[number, number]>([0, columnsOfConductor]);
  const [activeData, setActiveData] = useState<{ sliceIndex: number, generalIndex: number }>({ sliceIndex: 0, generalIndex: activeIndex });

  const handlePrevTabs = () => {
    if (startEnd[0] > 0) {
      setStartEnd((value) => [(value[0] - 1), (value[1] - 1)]);
      if (activeData.sliceIndex === (columnsOfConductor - 1)) {
        onChange(activeData.generalIndex - 1);
        setActiveData({ ...activeData, generalIndex: activeData.generalIndex - 1 });
      } else {
        setActiveData({ ...activeData, sliceIndex: activeData.sliceIndex + 1 });
      }
    }
  };

  const handleNextTabs = () => {
    if (startEnd[1] < tabs.length) {
      setStartEnd((value) => [(value[0] + 1), (value[1] + 1)]);
      if (activeData.sliceIndex === 0) {
        onChange(activeData.generalIndex + 1);
        setActiveData({ ...activeData, generalIndex: activeData.generalIndex + 1 });
      } else {
        setActiveData({ ...activeData, sliceIndex: activeData.sliceIndex - 1 });
      }
    }
  };

  return (
    <Flex justify={'center'} align="center">
      <IconRightArrowWrapper>
        <IconWrapper size='large'
          notShow={startEnd[0] === 0}
          onClick={() => handlePrevTabs()} >
          <IconRightArrow />
        </IconWrapper>
      </IconRightArrowWrapper>
      <LiveTabBarWrapper {...rest}>
        {tabs.slice(startEnd[0], startEnd[1]).map((tab, index) => (
          <LiveTab
            key={tab.short_id}
            active={tab.i === activeIndex}
            onClick={() => {
              setActiveData({ sliceIndex: index, generalIndex: tab.i });
              onChange(tab.i);
            }}
            customWidth={customTabWidth}
            textAlign={tabTextAlign}
            columnsOfConductor={columnsOfConductor}
          >
            <Text variant='body1'>
              <TitleColoredText columnsOfConductor={columnsOfConductor}>
                {tab.display_title.split('|')[0]}
              </TitleColoredText>
            </Text>
            <Text variant='body3'>
              <SubTitleColoredText color={theme.palette.foreground[500][0]} columnsOfConductor={columnsOfConductor}>
                {tab.display_title.split('|')[1]}
              </SubTitleColoredText>
            </Text>
          </LiveTab>
        ))}
      </LiveTabBarWrapper>
      <IconLeftArrowWrapper>
        <IconWrapper size='large' onClick={() => handleNextTabs()}
          notShow={startEnd[1] === tabs.length}>
          <IconLeftArrow />
        </IconWrapper>
      </IconLeftArrowWrapper>
    </Flex>
  );
}

const IconRightArrowWrapper = styled.span(() => ({
  position: 'absolute',
  right: '13px',
}));

const IconLeftArrowWrapper = styled.span(() => ({
  position: 'absolute',
  left: '13px',
}));

const TitleColoredText = styled.span(({ columnsOfConductor }: { columnsOfConductor: number }) => ({
  fontSize: columnsOfConductor <= 3 ? '14px' : '16px',
}));

const IconWrapper = styled(Icon)<{ notShow: boolean }>(({ notShow }) => ({
  display: notShow ? 'none' : 'block',
  cursor: 'pointer',
  '&:hover, &:focus': {
    backgroundColor: 'transparent',
    'svg path': {
      strokeWidth: 3,
    },
  },
}));

const SubTitleColoredText = styled.span(({ color, columnsOfConductor }: { color?: string, columnsOfConductor: number }) => ({
  color,
  fontSize: columnsOfConductor <= 3 ? '12px' : '14px',
}));
