import styled from '@emotion/styled';
import Spinner from './Spinner';
export type Size = 'inherit' | 'small' | 'large' | 'huge' | 'medium';

const wrapperSizes = {
  small: 1.5,
  inherit: 2,
  medium: 3,
  large: 6,
  huge: 6,
};
const IconButtonWrapper = styled.div<{ size?: Size }>(({ size = 'inherit', theme }) => ({
  backgroundColor: theme.palette.primary[900][0],
  transition: 'transform 200ms linear',
  width: `${wrapperSizes[size]}rem`,
  height: `${wrapperSizes[size]}rem`,
  borderRadius: '50%',
  position: 'relative',
  '&:hover, &:active, &:focus': {
    backgroundColor: theme.palette.primary[800][0],
    transform: 'scale(1.1)',
  },
}));
const SpinnerButtonChild = ({ size }: { size: Size, round?: boolean }) => {
  return (
    <IconButtonWrapper >
      <Spinner size={size} />
    </IconButtonWrapper>
  );
};
const SpinnerButtonWrapper = styled('div')(({ theme }) => ({
  opacity: 1,
  position: 'absolute',
  zIndex: 1,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) scale(1)',
  padding: theme.spacing(5),
  fontSize: '1.5rem',
}));

const LiveSpinnerBtn = ({ size }: any) => {
  return <SpinnerButtonWrapper >
    <SpinnerButtonChild size={size} />
  </SpinnerButtonWrapper>;
};

export default LiveSpinnerBtn;
