interface LiveStreamWidgetItem {
  short_id?: string
  video_content?: {
    short_id?: string
  }
}

interface LiveStreamWidgetModel {
  configuration: {
    items: Array<LiveStreamWidgetItem>
  }
}

export function liveStreamWidgetsIdExtractor<T extends LiveStreamWidgetModel>(widgets: Array<T>): Array<string> {
  if (widgets.length === 0) return [];
  const arrayOfIds: Array<string> = [];
  for (let i = 0; i < widgets.length; i++) {
    if (widgets[i] && widgets[i].configuration && widgets[i].configuration.items && Array.isArray(widgets[i].configuration.items) && widgets[i].configuration.items.length) {
      for (let j = 0; j < widgets[i].configuration.items.length; j++) {
        if (widgets[i].configuration.items[j].short_id && typeof widgets[i].configuration.items[j].short_id === 'string') {
          arrayOfIds.push(widgets[i].configuration.items[j].short_id || '');
        } else if (widgets[i].configuration.items[j].video_content && widgets[i].configuration.items[j].video_content?.short_id && typeof widgets[i].configuration.items[j].video_content?.short_id === 'string') {
          arrayOfIds.push(widgets[i].configuration.items[j].video_content?.short_id || '');
        }
      }
    }
  }
  return arrayOfIds.filter((item: string) => item !== '');
}
