import { WidgetLiveItemCarousel } from './apiService';
import LiveCard from './LiveCard';
import { Text } from './styles';
import Row from './styles/Row';

type Size = 'small' | 'normal' | 'large';

const cols = {
  normal: { xs: 2, sm: 3, md: 4, lg: 5, xl: 5 },
  large: { xs: 2, md: 3, lg: 4, xl: 5 },
};

const HottestLiveList = ({ items, size = 'normal', title, isArchive, playAbleLoading }: { items: WidgetLiveItemCarousel[]; size?: Size, title?: string, isArchive?: boolean, playAbleLoading: boolean }) => {
  return <>
    {title && (
      <Text variant="h2" weight="medium" mb={1} mt={4}>
        {title}
      </Text>
    )}
    <Row cols={cols[size]}>
      {items.map((item) => <LiveCard key={item.content_id} {...item} isArchive={isArchive} playAbleLoading={playAbleLoading} />)}
    </Row>
  </>;
};

export default HottestLiveList;
