import { useUser } from 'common/CacheProvider';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CinemaTicket } from './useConditionalProps';

const BASE_URL = '/play'; // process.env.NEXT_PUBLIC_PLAYER_BASE_URL;
export const getPlayerUrl = ({ id, type = 'p' }: { id: string, type?: VideoType }) => `${BASE_URL}/${id}/${type}`;

export type VideoType = 'p' | 't';

type PlayerServiceType = [(type: VideoType) => void, { loading: boolean; error?: any; action: PlayAction | null }];

type PlayerServiceProps = {
  id: string;
  free?: boolean;
  ticket?: CinemaTicket;
  showtime?: number;
  playable?: boolean,
  hasAccess?: boolean,
  hasSpecificCarrie?: boolean
};

export default function usePlayerService({ id, free, ticket, showtime, playable, hasAccess,
  hasSpecificCarrie }: PlayerServiceProps): PlayerServiceType {
  const router = useRouter();
  const { authorized, hasSubscription, loaded } = useUser();
  const [loading, setLoading] = useState<boolean>(!loaded);
  const ticketUrl = ticket?.href || '';

  useEffect(() => setLoading(false), [id]);
  const action: PlayAction = useMemo(() => {
    if (!playable && ticketUrl && !showtime) return 'purchase';
    if (loaded && !authorized) return 'login';
    if (hasSpecificCarrie) return 'carrierWatch';
    if (!hasSubscription) {
      if (free) return 'watchFree';
      if (!showtime) return 'subscribe';
      if (playable && hasAccess) return 'watchAccess';
    }
    if (playable && hasAccess) return 'watchAccess';
    return 'watchAccess';
  }, [ticketUrl, showtime, loaded, authorized, hasSubscription, free, playable, hasAccess, hasSpecificCarrie]);

  const callback = useCallback(
    (type: VideoType) => {
      setLoading(true);
      if (!loaded) return;

      const getRouteOptions = (): [path: string, redirect?: string] => {
        const playerUrl = getPlayerUrl({ id, type });
        if (type !== 'p') return [playerUrl];

        switch (action) {
          case 'login':
            return ['/login', playerUrl];

          case 'subscribe':
            return ['/packages', playerUrl];

          case 'purchase':
            if (!authorized) return ['/login', ticketUrl];
            if (authorized) return [ticketUrl];
        }

        return [playerUrl];
      };

      const [path, redirect] = getRouteOptions();
      const route = [path, redirect]
        .filter((p) => !!p)
        .map((p) => p && decodeURIComponent(p))
        .join('?redirect=');

      router.push(route);
    },
    [loaded, router, id, action, authorized, ticketUrl],
  );

  return [callback, { action, loading }];
}

export type PlayAction = 'login' | 'watchFree' | 'subscribe' | 'watchAccess' | 'purchase' | 'carrierWatch';
