import Carousel from './Carousel';
import HottestLiveList from './HottestLiveList';

const LiveArchiveList = ({ items, display_title, playAbleLoading }) => {
  return (
    <Carousel m={0} title={display_title} more={''} mt={4}>
      <HottestLiveList items={items} isArchive playAbleLoading={playAbleLoading} />
    </Carousel>
  );
};

export default LiveArchiveList;
