import styled from '@emotion/styled';
import { paperBg } from './Paper';

type LiveTabProps = {
  columnsOfConductor: number,
  active?: boolean,
  customWidth?: string
  textAlign?: 'right' | 'left'
};

const LiveTab = styled.button<LiveTabProps>(({ theme, active, customWidth, textAlign }) => ({
  fontSize: '16px',
  height: 'auto',
  textAlign: textAlign ?? 'center',
  backgroundColor: active ? theme.palette.background[paperBg.strong][0] : undefined,
  borderRadius: '4px',
  paddingRight: textAlign && textAlign === 'right' && active ? theme.spacing(2) : theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: textAlign && textAlign === 'left' && active ? theme.spacing(2) : theme.spacing(2),
  transition: 'border-color 0.5s ease, background-color 0.5s ease, padding-right 0.5s, padding-left 0.5s',
  cursor: 'pointer',
  width: customWidth ?? '100%',
  ...theme.font.responsiveSize(2),
  '&:hover, &:focus, &:active': {
    backgroundColor: theme.palette.background[paperBg.strong][0],
  },
}));

export default LiveTab;
