import React from 'react';

export default function IconEmptyLiveSchedule() {
  return (
    <svg width="182" height="182" viewBox="0 0 182 182" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 44.2478C0 40.0589 3.39517 36.6631 7.58333 36.6631H174.417C178.605 36.6631 182 40.0589 182 44.2478V174.417C182 178.605 178.605 182.001 174.417 182.001H7.58334C3.39518 182.001 0 178.605 0 174.417V44.2478Z" fill="#C4C4C4" />
      <path d="M70.1537 7.07269C69.1072 5.25973 69.7282 2.94151 71.5408 1.8948C73.3535 0.848084 75.6713 1.46925 76.7178 3.28221L95.6442 36.0697C96.6907 37.8827 96.0697 40.2009 94.257 41.2476C92.4444 42.2943 90.1266 41.6732 89.0801 39.8602L70.1537 7.07269Z" fill="#C4C4C4" />
      <path d="M114.129 7.07269C115.175 5.25973 114.554 2.94151 112.741 1.8948C110.929 0.848084 108.611 1.46925 107.564 3.28221L88.638 36.0697C87.5915 37.8827 88.2126 40.2009 90.0252 41.2476C91.8378 42.2943 94.1556 41.6732 95.2021 39.8602L114.129 7.07269Z" fill="#C4C4C4" />
      <path d="M16.6719 64.6028C16.6719 58.4644 21.648 53.4883 27.7864 53.4883H154.215C160.353 53.4883 165.329 58.4644 165.329 64.6028V154.214C165.329 160.352 160.353 165.329 154.215 165.329H27.7864C21.648 165.329 16.6719 160.352 16.6719 154.214V64.6028Z" fill="#363636" />
      <path fillRule="evenodd" clipRule="evenodd" d="M54.5084 110.103C54.5084 106.842 57.152 104.198 60.413 104.198H83.6893C89.1183 104.198 92.2249 110.388 88.9807 114.741L69.399 141.015H85.0734C88.3344 141.015 90.978 143.659 90.978 146.92C90.978 150.181 88.3344 152.824 85.0734 152.824H59.0184C53.5895 152.824 50.4829 146.635 53.7271 142.282L73.3087 116.007H60.413C57.152 116.007 54.5084 113.364 54.5084 110.103Z" fill="#C4C4C4" />
      <path fillRule="evenodd" clipRule="evenodd" d="M104.729 68.6084C104.729 66.0126 106.841 63.9082 109.445 63.9082H128.037C132.373 63.9082 134.855 68.8355 132.264 72.3006L116.623 93.2155H129.143C131.747 93.2155 133.859 95.3198 133.859 97.9157C133.859 100.512 131.747 102.616 129.143 102.616H108.331C103.995 102.616 101.514 97.6886 104.105 94.2235L119.746 73.3086H109.445C106.841 73.3086 104.729 71.2043 104.729 68.6084Z" fill="#C4C4C4" />
    </svg>
  );
}
