import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { WidgetLiveItemCarousel } from './apiService';
import IconLiveStreaming from './icons/IconLiveStreaming';
import LivePlayButton from './LivePlayButton';
import { Card, CardBody, Icon, Text, Link } from './styles';
import SpinnerButton from './styles/SpinnerButton';
import { useResponsiveUrl } from './utils';
import theme from './theme/theme';

export default function FLiveCard(props: WidgetLiveItemCarousel & { isArchive: boolean | undefined, playAbleLoading: boolean }) {
  const imgUrl = useResponsiveUrl({ url: props.video_content.cover_image?.path, desktop: 500, mobile: 250 });
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  const [isHovering, setIsHovering] = useState(false);
  useEffect(() => {
    if (referenceElement) {
      const handleMouseEnter = () => setIsHovering(true);
      const handleMouseLeave = () => setIsHovering(false);

      referenceElement.addEventListener('mouseenter', handleMouseEnter);
      referenceElement.addEventListener('focus', handleMouseEnter);
      referenceElement.addEventListener('mouseleave', handleMouseLeave);
      referenceElement.addEventListener('blur', handleMouseLeave);
      return () => {
        referenceElement.removeEventListener('mouseenter', handleMouseEnter);
        referenceElement.removeEventListener('focus', handleMouseEnter);
        referenceElement.removeEventListener('mouseleave', handleMouseLeave);
        referenceElement.removeEventListener('blur', handleMouseLeave);
      };
    }
  }, [referenceElement]);

  return (
    <>
      <Card
        orientation="horizontal"
        title={props.video_content.title}
        block
        as={props.isArchive ? Link : Card}
        href={`contents/${props.video_content.short_id}/${props.video_content.slug}`}
        ref={setReferenceElement}
        style={{ marginBottom: props.isArchive ? '8px' : '' }}
      >
        <LiveCardBody style={{ backgroundImage: imgUrl && `url(${imgUrl})` }} isArchive={props.isArchive} />
        {!props.playAbleLoading && props.playable ? <LivePlayButton id={props.video_content.short_id} size="medium" hide={!isHovering} /> : props.playAbleLoading ? <SpinnerButton size={'medium'} /> : ''}

      </Card>
      <Text variant={props.isArchive ? 'body3' : 'body2'} my='0.5rem' style={{ color: props.isArchive ? theme.palette.foreground[500][0] : theme.palette.foreground[900][0]  }}>{props.video_content.title}</Text>
      {props.playable && !props.isArchive
        ? <>
          <Text variant="body3" mb='1rem'>
            <Icon size="small">
              <IconLiveStreaming />
            </Icon>
            <span style={{ paddingRight: '4px', color: theme.palette.foreground[500][0]  }}>در حال پخش</span>
          </Text>
        </>
        : !props.isArchive ? <Text variant="body3" style={{ paddingRight: '4px', color: theme.palette.foreground[500][0] }}> {props.live_description} </Text> : null
      }
    </>
  );
}

const LiveCardBody = styled(CardBody)<{ isArchive?: boolean }>(({ isArchive }) => ({
  opacity: 1,
  backgroundSize: 'cover',
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  transition: 'transform 2.5s',
  transform: 'scale(1, 1)',
  '&:hover, &:active, &:focus': {
    transform: isArchive ? 'scale(1.4, 1.4)' : undefined,
  },
}));
