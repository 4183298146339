import { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { BreakpointKeys } from 'common/theme';

type useResponsiveUrlProps = {
  url?: string,
  breakpoint?: BreakpointKeys,
  desktop: number,
  mobile: number,
};

function useResponsiveUrl({ url, breakpoint = 'md', desktop, mobile }: useResponsiveUrlProps) {
  const [newUrl, setNewUrl] = useState('');
  const { breakpoints } = useTheme();

  useEffect(() => {
    if (window.innerWidth > breakpoints[breakpoint]) {
      setNewUrl(`${url}?w=${desktop}`);
    } else {
      setNewUrl(`${url}?w=${mobile}`);
    }
  }, [breakpoint, breakpoints, mobile, desktop, url]);

  return newUrl;
}

export default useResponsiveUrl;
