import styled from '@emotion/styled';
import IconButton from './IconButton';
import Spinner from './Spinner';
export type Size = 'inherit' | 'small' | 'large' | 'huge' | 'medium';

const SpinnerButtonChild = ({ size, round = true }: { size: Size, round?: boolean }) => {
  return (
    <IconButton variant="primaryA" round={round} >
      <Spinner size={size} />
    </IconButton>
  );
};
const SpinnerButtonWrapper = styled('div')(({ theme }) => ({
  opacity: 1,
  position: 'absolute',
  zIndex: 1,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) scale(1)',
  padding: theme.spacing(5),
  fontSize: '1.5rem',
}));

const SpinnerButton = ({ size }: any) => {
  return <SpinnerButtonWrapper >
    <SpinnerButtonChild size={size} />
  </SpinnerButtonWrapper>;
};

export default SpinnerButton;
